<template>
    <div>
        <div class="product-detail">
            <a href="/">返回首页</a>
            <div class="title-header">
                <div class="header-img">
                    <img src="../../components/images/custmor.png">
                </div>
                <div class="header-info">
                    <div class="software-name">同城CALL</div>
                    <div class="software-company">恒臻科技（大连）有限责任公司</div>
                </div>
            </div>

            <div class="title-download">
                <a href="/src/assets/apk/用户端.apk" download="">点我下载APP</a>
            </div>

            <div class="title-content">
                <div class="content-name">产品介绍</div>
                <div class="content-info">
                    同城CALL是由恒臻科技（大连）有限责任公司编写的一款APP应用，本应用致力于真实、高效、免费的本地服务，方便您的生活。<br/>
                    用户在完成登录注册操作后，可以发布自身的家政服务需求，在被技工接收订单后上门为消费者完成服务内容，用户可以根据技工的服务质量为此次订单进行评价打分操作。并可以修改自己的个人信息，提出反馈意见等操作。
                </div>
            </div>
            <div class="title-footer">
                <img src="../../components/images/c-screenshot/1.png">
                <img src="../../components/images/c-screenshot/2.png">
                <img src="../../components/images/c-screenshot/3.png">
                <img src="../../components/images/c-screenshot/4.png">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CityCallCustomer',
        components: {},
        data() {
            return {}
        },
        mounted() {
        },
        methods: {}
    }
</script>

<style scoped>
    .product-detail {
        width: 70vw;
        margin: 0px 15vw;
        padding: 30px 60px;
        min-width: 400px;
        min-height: 600px;
        background-color: #F0F8FF;
    }

    .title-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
    }

    .header-img {
        width: 120px;
        height: 120px;
    }

    .header-img img {
        width: 120px;
        height: 120px;
        border-radius: 20px;
    }

    .header-info {
        width: calc(100% - 240px);
        margin: 0px 0px 0px 20px;
    }

    .software-name {
        font-size: 24px;
        font-weight: bold;
    }

    .software-company {
        font-size: 16px;
        color: dimgray;
    }

    .title-content {
        width: calc(100% - 120px);
        height: 200px;
        padding: 10px 60px;
    }

    .content-name {
        font-size: 18px;
        font-weight: bold;
    }

    .content-info {
        margin: 10px 0px 0px 0px;
        font-size: 14px;
    }

    .title-footer {
        width: 100%;
        height: 260px;
        padding: 0px 60px;
    }

    .title-footer img {
        width: 135px;
        height: 240px;
        margin: 0px 20px;
        border: 1px dashed #999;
    }


    .title-download {
        width: calc(100% - 120px);
        margin: 10px 60px 26px;
        font-weight: 700;
    }
</style>
